import conf from './conf'

const BASE_URL = conf.value('VUE_APP_REPORTS_URL')

export function activityRequestReport(params) {
  params.title = 'ActivityRequest'
  params.mode = 'csv'
  generateReport(params)
}

export function vehicleRequestReport(params) {
  params.title = 'vehicleRequestReport'
  params.mode = 'csv'
  generateReport(params)
}

export function employeeDailyReport(params) {
  params.title = 'EmployeeDailyReport'
  params.mode = 'xls'
  generateReport(params)
}

export function projectSummaryReport(params) {
  params.title = 'projectSummaryReport'
  params.mode = 'xls'
  generateReport(params)
}

export function pileMovementReport(params) {
  params.title = 'PileMovement'
  params.mode = 'xls'
  generateReport(params)
}

export function ddtReport(params) {
  params.title = 'Ddt'
  params.mode = 'xpdf'
  generateReport(params)
}

export function generateReport(params) {
  window.open(BASE_URL + 'reports?' + _fromObject(params))
}

// FIXME clean this methods
const _isObj = function(a) {
  return !!a && a.constructor === Object
}

// FIXME clean this methods
const _st = function(z, g) {
  return '' + (g !== '' ? '[' : '') + z + (g !== '' ? ']' : '')
}

// FIXME clean this methods
const _fromObject = function(params, skipObjects, prefix) {
  if (skipObjects === void 0) {
    skipObjects = false
  }

  if (prefix === void 0) {
    prefix = ''
  }

  let result = ''
  if (typeof (params) != 'object') {
    return prefix + '=' + encodeURIComponent(params) + '&'
  }

  for (const param in params) {
    const c = '' + prefix + _st(param, prefix)
    if (_isObj(params[param]) && !skipObjects) {
      result += _fromObject(params[param], false, '' + c)
    } else if (Array.isArray(params[param]) && !skipObjects) {
      params[param].forEach(function(item) {
        result += _fromObject(item, false, c)
      })
    } else {
      result += c + '=' + encodeURIComponent(params[param]) + '&'
    }
  }

  return result
}
